/* In a separate CSS file, e.g., comingSoonStyles.css */
.coming-soon-container {
    font-family: math;
    text-align: center;
    margin: 100px auto;
    max-width: 400px;
    color: #252323;
  }
  
  .coming-soon-container h1 {
    font-size: 36px;
    color: #333;
  }
  
  .coming-soon-container p {
    font-size: 18px;
    color: #666;
  }
  .logo {
      justify-content: center;
      display: flex;
      height: 200px;
  }
  h2 {
      padding-top: 2rem;
      font-size: 1.8rem;
      color: #252323;;
  }

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

body {
  margin: 0;
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.activeHeaderMenu {
  @apply bg-secondary-focus/10;
}

.note {
  background: #fff;
  border: 2px solid #5551ff;
  border-radius: 8px;
  position: relative;
  top: -3px;
  right: 0;
  margin-top: 20px;
  padding: 14px;
  max-width: fit-content;
}
.tip {
  background: #fff;
  border: 2px solid #2cc675;
  border-radius: 8px;
  position: relative;
  top: -3px;
  right: 0;
  margin-top: 20px;
  padding: 14px;
  max-width: fit-content;
}

.liclass {
  color: rgb(59 130 246 / 0.5);
}
.aclass {
  color: #5551ff;
}